'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    width: 100%;\n  '], ['\n    width: 100%;\n  ']);

var _framerMotion = require('framer-motion');

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _v = require('uuid/v1');

var _v2 = _interopRequireDefault(_v);

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var PageOverlay = (0, _styledComponents2.default)(_framerMotion.motion.div).withConfig({
  displayName: 'SlideMenu__PageOverlay',
  componentId: 'sc-1k1chc2-0'
})(['position:fixed;inset:0;top:0;right:0;width:100%;height:100%;z-index:11;']);

var Drawer = (0, _styledComponents2.default)(_framerMotion.motion.div).withConfig({
  displayName: 'SlideMenu__Drawer',
  componentId: 'sc-1k1chc2-1'
})(['display:flex;flex-direction:column;box-sizing:border-box;align-items:end;justify-content:center;height:100%;background:rgba(0,0,0,0.002);backdrop-filter:blur(150px) saturate(1.8);position:fixed;top:0;left:0;overflow:hidden;width:calc(43% + 16px);max-width:100%;z-index:15;padding:0;padding-right:calc(16px + 6.5vw);', ''], _theme2.default.max.medium(_templateObject));

var LinkContainer = _styledComponents2.default.div.withConfig({
  displayName: 'SlideMenu__LinkContainer',
  componentId: 'sc-1k1chc2-2'
})(['display:flex;flex-direction:column;justify-content:center;align-items:end;width:100;gap:30px;box-sizing:border-box;']);

var Link = (0, _styledComponents2.default)(_framerMotion.motion.div).withConfig({
  displayName: 'SlideMenu__Link',
  componentId: 'sc-1k1chc2-3'
})(['color:white;cursor:pointer;font-size:16px;letter-spacing:1.88px;line-height:20.94px;font-weight:500;opacity:0.5;font-family:\'RivieraNights-Regular\';']);

var LinkSpan = _styledComponents2.default.span.withConfig({
  displayName: 'SlideMenu__LinkSpan',
  componentId: 'sc-1k1chc2-4'
})(['opacity:0.5;&:hover,&:active{opacity:1;}transition:all 0.25s;']);

var HiddenLanguagePicklist = _styledComponents2.default.div.withConfig({
  displayName: 'SlideMenu__HiddenLanguagePicklist',
  componentId: 'sc-1k1chc2-5'
})(['font-family:\'RivieraNights-Medium\',Arial;font-size:16px;font-weight:500;text-transform:uppercase;letter-spacing:1.88px;']);

var LanguagePicklist = _styledComponents2.default.select.withConfig({
  displayName: 'SlideMenu__LanguagePicklist',
  componentId: 'sc-1k1chc2-6'
})(['background:none;border:none;cursor:pointer;color:#000;font-family:\'RivieraNights-Medium\',Arial;font-size:16px;font-weight:500;text-transform:uppercase;letter-spacing:1.88px;box-shadow:none;appearance:none;width:calc(', 'px + 25px);background-image:url(\'https://res.cloudinary.com/motortrak/v1709224292/locator/rolls-royce/global/icons/chev-down.svg\');background-position-x:calc(100%);background-position-y:50%;background-repeat:no-repeat;filter:invert(1);padding-left:5px;&:focus{outline:none;border:none;}'], function (_ref) {
  var width = _ref.width;
  return width;
});

var PickListWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'SlideMenu__PickListWrapper',
  componentId: 'sc-1k1chc2-7'
})(['align-items:center;bottom:0;color:#fff;display:flex;font-family:\'RivieraNights-Regular\',Arial;font-size:16px;font-weight:500;justify-content:center;letter-spacing:1.88px;position:absolute;text-transform:uppercase;transition:opacity 0.25s;opacity:0.5;margin-bottom:30px;&:hover{opacity:1;}']);

var pageOverlayVariants = {
  open: {
    opacity: 0.5,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'block',
    transition: {
      duration: 0.5,
      ease: 'easeInOut'
    }
  },
  closed: {
    opacity: 0,
    backgroundColor: 'rgba(0, 0, 0, 0)',
    transition: {
      duration: 0.5,
      delay: 0.375,
      ease: 'easeInOut'
    },
    transitionEnd: {
      display: 'none'
    }
  }
};

var menuVariants = {
  slideIn: function slideIn(_ref2) {
    var _ref3 = (0, _slicedToArray3.default)(_ref2, 2),
        i = _ref3[0],
        length = _ref3[1];

    return {
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
        delay: (length - 1 - i) * 0.075
      }
    };
  },
  slideOut: function slideOut(_ref4) {
    var _ref5 = (0, _slicedToArray3.default)(_ref4, 2),
        i = _ref5[0],
        length = _ref5[1];

    return {
      x: '-300%',
      opacity: 0,
      initial: {
        x: 0,
        opacity: 1
      },

      transition: {
        duration: 0.5,
        delay: (length - 1 - i) * 0.075
      }
    };
  },
  freeze: {
    opacity: 1,
    x: 0
  }
};

var drawerVariants = {
  open: {
    x: 0,
    display: 'flex',
    transition: {
      duration: 0.75,
      ease: 'easeInOut'
    }
  },
  closed: {
    x: '-100%',
    transition: {
      delay: 0.5,
      duration: 0.8,
      ease: 'easeInOut'
    },
    transitionEnd: {
      display: 'none'
    }
  }
};

var SlideMenu = function SlideMenu(_ref6) {
  var config = _ref6.config,
      links = _ref6.links,
      navigate = _ref6.navigate,
      languages = _ref6.languages,
      selectedLanguage = _ref6.selectedLanguage,
      changeLanguage = _ref6.changeLanguage,
      open = _ref6.open,
      onClose = _ref6.onClose;

  var _useState = (0, _react.useState)(0),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      width = _useState2[0],
      setWidth = _useState2[1];

  var hiddenDivRef = (0, _react.useRef)(null);
  var initialAnimationRef = (0, _react.useRef)(true);
  document.body.style.overflow = open ? 'hidden' : 'auto';
  initialAnimationRef.current = open ? true : false;

  // Dynamically calculate width based on selected language
  (0, _react.useEffect)(function () {
    var calculateWidth = function calculateWidth(selectedValue) {
      if (hiddenDivRef.current) {
        var selectedOption = languages.find(function (option) {
          return option.value === selectedValue;
        });
        hiddenDivRef.current.textContent = selectedOption ? selectedOption.label : '';
        var newWidth = hiddenDivRef.current.offsetWidth;
        setWidth(newWidth);
      }
    };

    calculateWidth(selectedLanguage.value);
  }, [selectedLanguage.value, languages]);

  var onLanguageSelected = function onLanguageSelected(value, selectedLanguage) {
    if (changeLanguage && selectedLanguage !== value) {
      changeLanguage(languages.find(function (l) {
        return l.value === value;
      }));
    }
  };

  return _react2.default.createElement(
    _react.Fragment,
    null,
    _react2.default.createElement(HiddenLanguagePicklist, {
      style: {
        position: 'absolute',
        visibility: 'hidden',
        whiteSpace: 'nowrap'
      },
      ref: hiddenDivRef
    }),
    _react2.default.createElement(PageOverlay, {
      onClick: onClose,
      variants: pageOverlayVariants,
      initial: initialAnimationRef.current ? {} : 'closed',
      animate: open ? 'open' : initialAnimationRef.current ? {} : 'closed'
    }),
    _react2.default.createElement(
      Drawer,
      {
        initial: initialAnimationRef.current ? {} : 'closed',
        variants: drawerVariants,
        animate: open ? 'open' : initialAnimationRef.current ? {} : 'closed'
      },
      _react2.default.createElement(
        LinkContainer,
        null,
        links.map(function (item, i, array) {
          return _react2.default.createElement(
            Link,
            {
              key: (0, _v2.default)(),
              onClick: function onClick() {
                navigate(item.link);
                onClose();
              },
              initial: initialAnimationRef.current ? { x: '-300%', opacity: 0 } : open ? { opacity: 0, x: 0 } : { x: 0, opacity: 1 },
              custom: [i, array.length],
              animate: initialAnimationRef.current ? 'slideIn' : open ? 'freeze' : 'slideOut',
              variants: menuVariants
            },
            _react2.default.createElement(
              LinkSpan,
              null,
              config.translations['menuBarItems-' + i]
            )
          );
        })
      ),
      languages.length > 0 && _react2.default.createElement(
        PickListWrapper,
        null,
        config.translations.languagePrefix,
        _react2.default.createElement(
          LanguagePicklist,
          {
            value: selectedLanguage.value,
            onChange: function onChange(e) {
              onLanguageSelected(e.target.value, selectedLanguage);
            },
            width: width
          },
          languages.map(function (language) {
            return _react2.default.createElement(
              'option',
              { key: language.value, value: language.value },
              language.label
            );
          })
        )
      )
    )
  );
};

exports.default = SlideMenu;