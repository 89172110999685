'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = undefined;

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _v = require('uuid/v1');

var _v2 = _interopRequireDefault(_v);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Select = _styledComponents2.default.select.withConfig({
  displayName: 'LanguagePicklist__Select',
  componentId: 'sc-un3jyf-0'
})(['background:none;border:none;cursor:pointer;color:#222;font-size:12px;line-height:24px;letter-spacing:2px;font-family:\'RivieraNights-Medium\';text-transform:uppercase;box-shadow:none;appearance:none;padding-right:25px;width:', 'px;background-image:url(\'https://res.cloudinary.com/motortrak/v1709224292/locator/rolls-royce/global/icons/chev-down.svg\');background-position-x:calc(100%);background-position-y:50%;background-repeat:no-repeat;&:focus{outline:none;border:none;}'], function (_ref) {
  var width = _ref.width;
  return width;
});

// Variable width select that gets width of the selected option value
function LanguagePicklist(_ref2) {
  var font = _ref2.font,
      value = _ref2.value,
      _onChange = _ref2.onChange,
      options = _ref2.options,
      dataCy = _ref2.dataCy;

  var _useState = (0, _react.useState)(0),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      width = _useState2[0],
      setWidth = _useState2[1];

  var hiddenDivRef = (0, _react.useRef)(null);

  (0, _react.useEffect)(function () {
    var calculateWidth = function calculateWidth(selectedValue) {
      if (hiddenDivRef.current) {
        var selectedOption = options.find(function (option) {
          return option.value === selectedValue;
        });
        hiddenDivRef.current.textContent = selectedOption ? selectedOption.label : '';
        var newWidth = hiddenDivRef.current.offsetWidth;
        setWidth(newWidth + 30);
      }
    };

    calculateWidth(value);
  }, [value, options]);

  return _react2.default.createElement(
    'div',
    null,
    _react2.default.createElement('div', {
      style: {
        position: 'absolute',
        visibility: 'hidden',
        whiteSpace: 'nowrap'
      },
      ref: hiddenDivRef
    }),
    _react2.default.createElement(
      Select,
      {
        'data-cy': dataCy,
        font: font,
        value: value,
        onChange: function onChange(event) {
          _onChange(event);
          var selectedValue = event.target.value;
          setTimeout(function () {
            if (hiddenDivRef.current) {
              hiddenDivRef.current.textContent = options.find(function (opt) {
                return opt.value === selectedValue;
              }).label;
              setWidth(hiddenDivRef.current.offsetWidth + 30);
            }
          }, 0);
        },
        width: width
      },
      options.map(function (lang) {
        return _react2.default.createElement(
          'option',
          { key: (0, _v2.default)(), value: lang.value },
          lang.label
        );
      })
    )
  );
}
exports.default = LanguagePicklist;