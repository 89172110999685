'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    font-size: 10px;\n  '], ['\n    font-size: 10px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _SocialSharePopup = require('../../../components/SocialSharePopup');

var _SocialSharePopup2 = _interopRequireDefault(_SocialSharePopup);

var _RollsRoyce = require('../../../customThemes/RollsRoyce');

var _RollsRoyce2 = _interopRequireDefault(_RollsRoyce);

var _types = require('./types');

var _Spinner = require('../../Spinner');

var _Spinner2 = _interopRequireDefault(_Spinner);

var _Button = require('../Form/Button');

var _Button2 = _interopRequireDefault(_Button);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ActionContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VDPActions__ActionContainer',
  componentId: 'sc-sp0t2k-0'
})(['display:flex;margin-left:auto;']);

var ButtonContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VDPActions__ButtonContainer',
  componentId: 'sc-sp0t2k-1'
})(['align-items:center;display:flex;padding-left:40px;']);

var SpinnerContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VDPActions__SpinnerContainer',
  componentId: 'sc-sp0t2k-2'
})(['align-items:center;display:flex;justify-content:center;padding-left:55px;']);

var TextButtton = _styledComponents2.default.button.withConfig({
  displayName: 'VDPActions__TextButtton',
  componentId: 'sc-sp0t2k-3'
})(['align-items:center;background:none;border:none;color:#222;cursor:pointer;display:flex;font:inherit;font-size:12px;letter-spacing:2px;line-height:24px;outline:inherit;padding:0;padding-left:32px;text-transform:uppercase;transition:color 0.2s linear;&:hover,&:active{opacity:0.7;}', ';'], _RollsRoyce2.default.max.medium(_templateObject));

var primaryButtonStyle = function primaryButtonStyle() {
  return '\n  min-width: 225px;\n  &:hover,\n  &:active {\n    background-color: #502387;\n  }\n';
};

var VDPActions = function VDPActions(_ref) {
  var config = _ref.config,
      socialShare = _ref.socialShare,
      onAddToShortlist = _ref.onAddToShortlist,
      onAddToCompare = _ref.onAddToCompare,
      onPrint = _ref.onPrint,
      onEnquire = _ref.onEnquire,
      isVehicleShortlisted = _ref.isVehicleShortlisted,
      isVehicleCompared = _ref.isVehicleCompared,
      printing = _ref.printing,
      enableEnquire = _ref.enableEnquire;
  var _config$translations = config.translations,
      addToShortlist = _config$translations.addToShortlist,
      addedToShortlist = _config$translations.addedToShortlist,
      addedToCompare = _config$translations.addedToCompare,
      addToCompare = _config$translations.addToCompare,
      socialShareText = _config$translations.socialShare,
      print = _config$translations.print,
      enquire = _config$translations.enquire;

  return _react2.default.createElement(
    ActionContainer,
    null,
    !config.hideShortlistCompare && _react2.default.createElement(
      TextButtton,
      { onClick: onAddToShortlist },
      isVehicleShortlisted ? addedToShortlist : addToShortlist
    ),
    !config.hideShortlistCompare && _react2.default.createElement(
      TextButtton,
      { onClick: onAddToCompare },
      isVehicleCompared ? addedToCompare : addToCompare
    ),
    !config.hideShare && _react2.default.createElement(
      _SocialSharePopup2.default,
      { config: config, socialShare: socialShare },
      _react2.default.createElement(
        TextButtton,
        null,
        socialShareText
      )
    ),
    printing ? _react2.default.createElement(
      SpinnerContainer,
      null,
      _react2.default.createElement(_Spinner2.default, {
        size: 10,
        colour: { label: 'rr-purple', value: '#6D30A7' }
      })
    ) : _react2.default.createElement(
      TextButtton,
      { onClick: onPrint },
      print
    ),
    enableEnquire && _react2.default.createElement(
      ButtonContainer,
      null,
      _react2.default.createElement(_Button2.default, {
        variant: 'primary',
        text: enquire,
        onClick: onEnquire,
        styleOverride: primaryButtonStyle,
        icon: 'https://res.cloudinary.com/motortrak/v1706726136/locator/rolls-royce/global/button-arrow.svg'
      })
    )
  );
};

exports.default = VDPActions;