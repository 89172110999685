'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = LocationDistanceFilter;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Global = require('../../components/Global');

var _LocationFilter = require('./LocationFilter');

var _SingleSelectFilter = require('./SingleSelectFilter');

var _SingleSelectFilter2 = _interopRequireDefault(_SingleSelectFilter);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ContentWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'LocationDistanceFilter__ContentWrapper',
  componentId: 'sc-1m558kg-0'
})(['padding:16px;']);


var HeadingWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'LocationDistanceFilter__HeadingWrapper',
  componentId: 'sc-1m558kg-1'
})(['padding-bottom:8px;']);

var FilterWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'LocationDistanceFilter__FilterWrapper',
  componentId: 'sc-1m558kg-2'
})(['padding-top:16px;']);

function LocationDistanceFilter(props) {
  return _react2.default.createElement(
    'div',
    null,
    _react2.default.createElement(
      ContentWrapper,
      null,
      _react2.default.createElement(_LocationFilter.Location, props),
      _react2.default.createElement(
        FilterWrapper,
        null,
        props.translations.searchIn && _react2.default.createElement(
          HeadingWrapper,
          null,
          _react2.default.createElement(
            _Global.HeadingThree,
            {
              styleOverride: function styleOverride() {
                return props.filtersStyling.filterHeadingFont;
              }
            },
            props.translations.searchIn
          )
        ),
        _react2.default.createElement(_SingleSelectFilter2.default, {
          filterKey: 'maxDistance',
          data: props.availableFilterValues.maxDistance,
          selectedData: props.selectedFilters.maxDistance,
          updateFilters: props.updateFilters,
          distanceToLabel: props.translations.distanceToLabel
        })
      )
    )
  );
}