'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)([' \n     &:hover {\n    opacity: 0.7;\n    transform: scale(1.05);\n    transition: opacity 0.4s, transform 0.4s;\n  }\n  '], [' \n     &:hover {\n    opacity: 0.7;\n    transform: scale(1.05);\n    transition: opacity 0.4s, transform 0.4s;\n  }\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)([' \n    &:hover ', ' {\n      transform: scale(1.05);\n      transition: opacity 0.4s, transform 0.4s;\n  }\n    &:hover {\n      opacity: 0.7;\n    }\n  '], [' \n    &:hover ', ' {\n      transform: scale(1.05);\n      transition: opacity 0.4s, transform 0.4s;\n  }\n    &:hover {\n      opacity: 0.7;\n    }\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)([' \n    width: 100%;\n  '], [' \n    width: 100%;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)([' \n      padding: 24px;\n      border-left: 1px solid #7c7c7c;\n      margin-bottom: 20px;\n  '], [' \n      padding: 24px;\n      border-left: 1px solid #7c7c7c;\n      margin-bottom: 20px;\n  ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)([' \n    min-height: 200px;\n  '], [' \n    min-height: 200px;\n  ']),
    _templateObject6 = (0, _taggedTemplateLiteral3.default)([' \n    min-height: 152px;\n  '], [' \n    min-height: 152px;\n  ']),
    _templateObject7 = (0, _taggedTemplateLiteral3.default)([' \n    margin-top: 12px;\n  '], [' \n    margin-top: 12px;\n  ']);

exports.default = RollsRoyceJourneyGridItem;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _framerMotion = require('framer-motion');

var _RollsRoyce = require('../../../customThemes/RollsRoyce');

var _RollsRoyce2 = _interopRequireDefault(_RollsRoyce);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var MotionJourneyGridItemWrapper = (0, _styledComponents2.default)(_framerMotion.motion.div).withConfig({
  displayName: 'RollsRoyceJourneyGridItem__MotionJourneyGridItemWrapper',
  componentId: 'sc-12hbt5k-0'
})(['display:flex;flex-direction:row;justify-content:center;width:100%;&:before{padding:0;margin:0;content:\'\';width:1px;height:42%;background-color:black;margin-left:-3px;}']);

var AspectRatioContainer = _styledComponents2.default.div.withConfig({
  displayName: 'RollsRoyceJourneyGridItem__AspectRatioContainer',
  componentId: 'sc-12hbt5k-1'
})(['overflow:hidden;position:relative;max-height:300px;@media only screen and (min-width:769px){max-height:400px;}']);

var MotionImage = _styledComponents2.default.img.withConfig({
  displayName: 'RollsRoyceJourneyGridItem__MotionImage',
  componentId: 'sc-12hbt5k-2'
})(['object-fit:cover;width:100%;height:auto;transition:all 0.5s;opacity:1;', ';'], _RollsRoyce2.default.min.large(_templateObject));

var JourneyLink = _styledComponents2.default.a.withConfig({
  displayName: 'RollsRoyceJourneyGridItem__JourneyLink',
  componentId: 'sc-12hbt5k-3'
})(['height:100%;width:100%;text-decoration:none;transition:all 0.5s;opacity:1;', ';'], _RollsRoyce2.default.min.large(_templateObject2, MotionImage));

var JourneyGridItemContainer = _styledComponents2.default.div.withConfig({
  displayName: 'RollsRoyceJourneyGridItem__JourneyGridItemContainer',
  componentId: 'sc-12hbt5k-4'
})(['display:flex;flex-direction:column;align-items:flex-start;', ';'], _RollsRoyce2.default.max.medium(_templateObject3));

var JourneyGridItemContentContainer = _styledComponents2.default.div.withConfig({
  displayName: 'RollsRoyceJourneyGridItem__JourneyGridItemContentContainer',
  componentId: 'sc-12hbt5k-5'
})(['padding:24px 0 33px 0;font-family:\'RivieraNights-Regular\';font-style:normal;color:#ffffff;', ';@media only screen and (min-width:769px){min-height:250px;margin-bottom:unset;}', ';', ';'], _RollsRoyce2.default.min.small(_templateObject4), _RollsRoyce2.default.min.medium(_templateObject5), _RollsRoyce2.default.min.large(_templateObject6));

var JourneyGridItemTitle = _styledComponents2.default.div.withConfig({
  displayName: 'RollsRoyceJourneyGridItem__JourneyGridItemTitle',
  componentId: 'sc-12hbt5k-6'
})(['font-size:16px;font-weight:400;line-height:28px;letter-spacing:2.5px;text-transform:uppercase;align-self:stretch;']);

var JourneyGridItemContent = _styledComponents2.default.p.withConfig({
  displayName: 'RollsRoyceJourneyGridItem__JourneyGridItemContent',
  componentId: 'sc-12hbt5k-7'
})(['font-family:\'RivieraNights-Light\',\'Jost-Lite\';font-size:14px;font-style:normal;font-weight:300;line-height:22px;letter-spacing:0.8px;align-self:stretch;padding:6px 0px;margin:0px;', ';'], _RollsRoyce2.default.min.large(_templateObject7));

function RollsRoyceJourneyGridItem(_ref) {
  var headline = _ref.headline,
      body = _ref.body,
      imageUrl = _ref.imageUrl,
      imageAlt = _ref.imageAlt,
      index = _ref.index,
      link = _ref.link;

  var baseDuration = 0.7; // Base duration for the animation
  var animationDuration = baseDuration + 0.3 * index; // Adjusted duration calculation
  return _react2.default.createElement(
    MotionJourneyGridItemWrapper,
    {
      initial: 'initialState',
      animate: 'animateState',
      exit: 'exitState',
      transition: { type: 'tween', duration: animationDuration },
      variants: {
        initialState: {
          opacity: 0,
          y: 100
        },
        animateState: {
          opacity: 1,
          y: 0
        }
      }
    },
    _react2.default.createElement(
      JourneyGridItemContainer,
      null,
      _react2.default.createElement(
        JourneyLink,
        { href: link, target: '_blank', rel: 'noopener noreferrer' },
        _react2.default.createElement(
          AspectRatioContainer,
          null,
          _react2.default.createElement(MotionImage, { src: imageUrl, alt: imageAlt })
        ),
        _react2.default.createElement(
          JourneyGridItemContentContainer,
          null,
          _react2.default.createElement(
            JourneyGridItemTitle,
            null,
            headline
          ),
          _react2.default.createElement(
            JourneyGridItemContent,
            null,
            body
          )
        )
      )
    )
  );
}