'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _keys = require('babel-runtime/core-js/object/keys');

var _keys2 = _interopRequireDefault(_keys);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    display: none;\n  '], ['\n    display: none;\n  ']);
/* eslint-disable react/jsx-indent */


var _difference = require('ramda/src/difference');

var _difference2 = _interopRequireDefault(_difference);

var _intersection = require('ramda/src/intersection');

var _intersection2 = _interopRequireDefault(_intersection);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _ContentWrapper = require('../../components/ContentWrapper');

var _ContentWrapper2 = _interopRequireDefault(_ContentWrapper);

var _FilterPill = require('../../components/FilterPill');

var _FilterPill2 = _interopRequireDefault(_FilterPill);

var _Global = require('../../components/Global');

var _filters = require('../../shared/filters');

var _numbers = require('../../shared/localisation/numbers');

var _translateFromTemplate = require('../../shared/localisation/translateFromTemplate');

var _sessionPreferences = require('../../shared/sessionPreferences');

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'FilterPills__Wrapper',
  componentId: 'sc-167zgcf-0'
})(['padding:0 15px 10px;min-height:48px;', ';'], _theme2.default.max.large(_templateObject));

var ResetFilters = _styledComponents2.default.a.withConfig({
  displayName: 'FilterPills__ResetFilters',
  componentId: 'sc-167zgcf-1'
})(['color:#9e1b32;font-size:16px;background:none;border:none;text-decoration:underline;cursor:pointer;']);

var PillsControls = _styledComponents2.default.div.withConfig({
  displayName: 'FilterPills__PillsControls',
  componentId: 'sc-167zgcf-2'
})(['display:flex;padding:15px 0;']);

var FilterPills = function (_Component) {
  (0, _inherits3.default)(FilterPills, _Component);

  function FilterPills(props) {
    (0, _classCallCheck3.default)(this, FilterPills);

    var _this = (0, _possibleConstructorReturn3.default)(this, (FilterPills.__proto__ || (0, _getPrototypeOf2.default)(FilterPills)).call(this, props));

    Object.defineProperty(_this, 'componentDidMount', {
      enumerable: true,
      writable: true,
      value: function value() {
        _this.createGeocoder();
        var shared = _this.props.shared;


        if (shared.sessionPreferences.location) {
          var _shared$sessionPrefer = shared.sessionPreferences.location,
              latitude = _shared$sessionPrefer.latitude,
              longitude = _shared$sessionPrefer.longitude;


          if (latitude && longitude) {
            _this.parseCoordinates(latitude, longitude);
          }
        }

        _this.setState(function () {
          return { attempts: 0 };
        });
      }
    });
    Object.defineProperty(_this, 'createGeocoder', {
      enumerable: true,
      writable: true,
      value: function value() {
        if (!_this.geocoder || _this.geocoder.notGeocoder) {
          var googleAvailable = _this.props.shared.google.googleAvailable;

          _this.geocoder = googleAvailable ? new window.google.maps.Geocoder() : { notGeocoder: true };
        }
      }
    });
    Object.defineProperty(_this, 'parseCoordinates', {
      enumerable: true,
      writable: true,
      value: function value(latitude, longitude) {
        _this.setState(function () {
          return { location: null };
        });
        if (!_this.geocoder.notGeocoder && latitude && longitude) {
          _this.geocoder.geocode({
            location: {
              lat: parseFloat(latitude),
              lng: parseFloat(longitude)
            }
          }, function (results, status) {
            var timeout = void 0;
            if (status === 'OVER_QUERY_LIMIT') {
              timeout = setTimeout(_this.setState(function (_ref) {
                var attempts = _ref.attempts;
                return { attempts: attempts + 1 };
              }), 2000);
              if (_this.state.attempts > 4) {
                return;
              }
              _this.parseCoordinates(latitude, longitude);
            }
            if (status === window.google.maps.GeocoderStatus.OK && results && results.length > 0) {
              var address = results.find(function (res) {
                return res.types.find(function (t) {
                  return t === 'locality';
                });
              }) || results.find(function (res) {
                return res.types.find(function (t) {
                  return t === 'postal_code';
                });
              });
              if (address) {
                clearTimeout(timeout);
                _this.setState(function () {
                  return { attempts: 0 };
                });
                _this.setState(function () {
                  return { location: address.formatted_address };
                });
              }
            }
          });
        }
      }
    });
    Object.defineProperty(_this, 'render', {
      enumerable: true,
      writable: true,
      value: function value() {
        var _this$props = _this.props,
            shared = _this$props.shared,
            router = _this$props.router,
            dispatch = _this$props.dispatch,
            globalStyling = _this$props.globalStyling,
            marketInfo = _this$props.marketInfo,
            _this$props$config = _this$props.config,
            translations = _this$props$config.translations,
            subHeadingFont = _this$props$config.subHeadingFont,
            showResetFiltersButton = _this$props$config.showResetFiltersButton,
            pillsFont = _this$props$config.pillsFont;


        var updateFilters = function updateFilters(payload) {
          return dispatch(_filters.actions.updateFilters(payload));
        };
        var eraseLocation = function eraseLocation() {
          return dispatch(_sessionPreferences.actions.updateSessionPreferences('location', {}));
        };
        var resetFilters = function resetFilters() {
          return dispatch(_filters.actions.resetFilters());
        };

        var distanceDisplay = function distanceDisplay(value) {
          return (0, _translateFromTemplate.translateFromTemplate)('distanceDisplayText', {
            VALUE: value
          }, translations);
        };
        var co2UnitDisplay = function co2UnitDisplay(value) {
          return (0, _translateFromTemplate.translateFromTemplate)('co2UnitDisplayText', {
            VALUE: value
          }, translations);
        };
        var odometerDisplay = function odometerDisplay(value) {
          return (0, _translateFromTemplate.translateFromTemplate)('odometerDisplayText', {
            VALUE: Number(value.toString()).toLocaleString()
          }, translations);
        };
        var fuelConsumptionDisplay = function fuelConsumptionDisplay(value) {
          return (0, _translateFromTemplate.translateFromTemplate)('fuelConsumptionText', {
            VALUE: Number(value.toString()).toLocaleString()
          }, translations);
        };

        var currentCurrency = shared.sessionPreferences.currency;
        var currencySymbol = currentCurrency || marketInfo.currencyCode || 'EUR';

        var priceDisplay = function priceDisplay(min, max) {
          return (0, _numbers.localiseCurrency)(Number(min), marketInfo.locale, currentCurrency || currencySymbol, 0) + ' -\n        ' + (0, _numbers.localiseCurrency)(Number(max), marketInfo.locale, currentCurrency || currencySymbol, 0);
        };

        var selectedFilters = shared && shared.filters && shared.filters.selectedFilters;

        var RangePill = function RangePill(_ref2) {
          var minFilterKey = _ref2.minFilterKey,
              maxFilterKey = _ref2.maxFilterKey,
              rangeDisplay = _ref2.rangeDisplay;

          var min = selectedFilters && selectedFilters[minFilterKey];
          var max = selectedFilters && selectedFilters[maxFilterKey];

          return min && max ? _react2.default.createElement(_FilterPill2.default, {
            pillText: rangeDisplay ? rangeDisplay(min, max) : min + ' - ' + max,
            onClose: function onClose() {
              updateFilters([{ key: minFilterKey, value: [] }, { key: maxFilterKey, value: [] }]);
            },
            font: pillsFont,
            direction: globalStyling.direction
          }) : null;
        };

        var getFilterDisplayText = function getFilterDisplayText(filterKey, item) {
          var availableFilterValues = shared && shared.filters && shared.filters.availableFilterValues && shared.filters.availableFilterValues[filterKey];

          var value = availableFilterValues && availableFilterValues.find(function (f) {
            return f.value.toString() === item;
          });

          // if item is missing from availableFilterValues, display the value from the API
          // this is to prevent issues where a filter is applied but doesn't display the filter pill
          var itemDisplay = item ? item.charAt(0).toUpperCase() + item.slice(1) : '';

          return !value ? itemDisplay : translations[value.display] || value.display;
        };

        var MultiItemsPills = function MultiItemsPills(_ref3) {
          var filterKey = _ref3.filterKey;

          var items = selectedFilters && selectedFilters[filterKey];
          return items && items.length > 0 ? items.map(function (item) {
            var text = getFilterDisplayText(filterKey, item);
            if (text.length === 0) return null;
            return _react2.default.createElement(_FilterPill2.default, {
              key: '' + filterKey + item,
              pillText: text,
              onClose: function onClose() {
                return updateFilters([{
                  key: filterKey,
                  value: _filters.helpers.newArray(items, item)
                }]);
              },
              font: pillsFont,
              direction: globalStyling.direction
            });
          }) : null;
        };

        var ValuePill = function ValuePill(_ref4) {
          var descriptionDisplay = _ref4.descriptionDisplay,
              filterKey = _ref4.filterKey;

          var value = selectedFilters && selectedFilters[filterKey];
          return value ? _react2.default.createElement(_FilterPill2.default, {
            pillText: descriptionDisplay(value),
            onClose: function onClose() {
              return updateFilters([{ key: filterKey, value: [] }]);
            },
            font: pillsFont,
            direction: globalStyling.direction
          }) : null;
        };
        ValuePill.defaultProps = {
          descriptionDisplay: function descriptionDisplay(value) {
            return value;
          }
        };

        var LocationPill = function LocationPill() {
          var _useSelector = (0, _reactRedux.useSelector)(function (state) {
            return {
              userLocationEnabled: state.config.config.global.featureFlags && state.config.config.global.featureFlags.userLocationEnabled
            };
          }),
              userLocationEnabled = _useSelector.userLocationEnabled;

          var value = _this.state.location;
          return value && !userLocationEnabled ? _react2.default.createElement(_FilterPill2.default, {
            pillText: value,
            onClose: function onClose() {
              eraseLocation();
            },
            font: pillsFont,
            direction: globalStyling.direction
          }) : null;
        };

        var updateFiltersForRegions = function updateFiltersForRegions(regionValue, regions, selectedData) {
          var subregions = regions[regionValue].filter(function (sr) {
            return sr.enabled;
          }).map(function (sr) {
            return sr.name;
          });
          var newFilterValue = (0, _difference2.default)(selectedData, subregions);
          updateFilters([{ key: 'subregions', value: newFilterValue }]);
        };

        var updateFiltersForSubregions = function updateFiltersForSubregions(subRegion, selectedData) {
          return updateFilters([{
            key: 'subregions',
            value: _filters.helpers.newArray(selectedData && selectedData.length > 0 ? selectedData.filter(Boolean) : [], subRegion)
          }]);
        };

        var RegionPills = function RegionPills() {
          var regions = shared.filters.availableFilterValues && shared.filters.availableFilterValues.regions;
          var selectedSubregions = selectedFilters && selectedFilters.subregions;
          var formatTitles = regions && (0, _keys2.default)(regions).map(function (key) {
            var enabledSubregionsForRegion = regions[key].some(function (r) {
              return r.enabled;
            }) && regions[key].filter(function (r) {
              return r.enabled;
            }).map(function (sr) {
              return sr.name;
            });
            var selected = selectedSubregions && selectedSubregions.length > 0 ? selectedSubregions : [];
            var enabledRegionKey = regions[key].some(function (r) {
              return r.enabled;
            }) && [key];
            return (0, _difference2.default)(enabledSubregionsForRegion, selected || []).length === 0 ? enabledRegionKey : (0, _intersection2.default)(enabledSubregionsForRegion, selected);
          }).filter(function (el) {
            return el.length > 0;
          }).flat();

          var _onClose = function _onClose(pillValue) {
            return (0, _keys2.default)(regions).some(function (r) {
              return r.toLowerCase() === pillValue.toLowerCase();
            }) ? updateFiltersForRegions(pillValue, regions, selectedSubregions) : updateFiltersForSubregions(pillValue, selectedSubregions);
          };

          return formatTitles && formatTitles.length > 0 ? formatTitles.sort().map(function (pill, index) {
            return _react2.default.createElement(_FilterPill2.default, {
              key: index,
              pillText: pill,
              onClose: function onClose() {
                return _onClose(pill);
              },
              font: pillsFont,
              direction: globalStyling.direction
            });
          }) : null;
        };

        var _router$pathProps = router.pathProps,
            retailerId = _router$pathProps.retailerId,
            dealerGroup = _router$pathProps.dealerGroup;

        var resultsCountLabel = shared.filters.total !== 1 ? shared.filters.total + ' ' + translations.searchResultsAvailableAtLabel : '' + translations.searchResultsAvailableAtSingleLabel;

        var getDealerNameOrGroup = function getDealerNameOrGroup(sharedFilters) {
          if (sharedFilters.dealer) {
            return sharedFilters.dealer.name;
          }

          if (sharedFilters.selectedFilters && sharedFilters.selectedFilters.dealerGroup) {
            return sharedFilters.selectedFilters.dealerGroup;
          }

          return '';
        };

        var retailerLabel = (0, _translateFromTemplate.translateFromTemplate)(shared.filters.total !== 1 ? 'searchResultsAvailableRetailerLabel' : 'searchResultsAvailableRetailerSingleLabel', {
          COUNT: shared.filters.total,
          RETAILER: getDealerNameOrGroup(shared.filters)
        }, translations);

        var singularOrPluralHeading = function singularOrPluralHeading() {
          return retailerId || dealerGroup ? retailerLabel : resultsCountLabel;
        };

        var availableVehiclesText = function availableVehiclesText() {
          return shared.filters.total !== undefined && singularOrPluralHeading();
        };

        return _react2.default.createElement(
          _ContentWrapper2.default,
          { contentWidth: globalStyling.contentWidth },
          _react2.default.createElement(
            Wrapper,
            null,
            _react2.default.createElement(
              PillsControls,
              null,
              _react2.default.createElement(
                _Global.Paragraph,
                {
                  styleOverride: function styleOverride() {
                    return '\n                    ' + (0, _Global.fontStyleOverride)(subHeadingFont) + '\n                    margin: 0;\n                ';
                  },
                  'data-cy': 'vehicles-available'
                },
                availableVehiclesText()
              ),
              showResetFiltersButton && _react2.default.createElement(
                ResetFilters,
                { onClick: resetFilters },
                'reset filters'
              )
            ),
            _react2.default.createElement(RegionPills, null),
            _react2.default.createElement(LocationPill, null),
            _react2.default.createElement(ValuePill, {
              descriptionDisplay: distanceDisplay,
              filterKey: 'maxDistance'
            }),
            _react2.default.createElement(RangePill, {
              minFilterKey: 'minPriceRange',
              maxFilterKey: 'maxPriceRange',
              rangeDisplay: priceDisplay
            }),
            !currentCurrency ? null : _react2.default.createElement(RangePill, {
              minFilterKey: 'minDefaultPrice',
              maxFilterKey: 'maxDefaultPrice',
              rangeDisplay: priceDisplay
            }),
            _react2.default.createElement(ValuePill, {
              descriptionDisplay: co2UnitDisplay,
              filterKey: 'co2Emissions'
            }),
            _react2.default.createElement(MultiItemsPills, { filterKey: 'model' }),
            _react2.default.createElement(MultiItemsPills, { filterKey: 'variant' }),
            _react2.default.createElement(MultiItemsPills, { filterKey: 'modelVariants' }),
            _react2.default.createElement(MultiItemsPills, { filterKey: 'vehicleTypes' }),
            _react2.default.createElement(MultiItemsPills, { filterKey: 'engine' }),
            _react2.default.createElement(MultiItemsPills, { filterKey: 'fuelType' }),
            _react2.default.createElement(MultiItemsPills, { filterKey: 'transmission' }),
            _react2.default.createElement(MultiItemsPills, { filterKey: 'bodystyle' }),
            _react2.default.createElement(MultiItemsPills, { filterKey: 'exteriorColours' }),
            _react2.default.createElement(MultiItemsPills, { filterKey: 'interiorShades' }),
            _react2.default.createElement(ValuePill, {
              descriptionDisplay: odometerDisplay,
              filterKey: 'maxOdometer'
            }),
            _react2.default.createElement(RangePill, { minFilterKey: 'yearRangeMin', maxFilterKey: 'yearRangeMax' }),
            _react2.default.createElement(ValuePill, {
              descriptionDisplay: fuelConsumptionDisplay,
              filterKey: 'maxFuelConsumption'
            }),
            _react2.default.createElement(MultiItemsPills, { filterKey: 'features' })
          )
        );
      }
    });


    _this.state = {
      attempts: 0
    };
    return _this;
  }

  (0, _createClass3.default)(FilterPills, [{
    key: 'componentDidUpdate',
    value: function componentDidUpdate(prevProps) {
      this.createGeocoder();
      var _props = this.props,
          shared = _props.shared,
          dispatch = _props.dispatch;
      var prevShared = prevProps.shared;


      var updateFilters = function updateFilters(payload) {
        return dispatch(_filters.actions.updateFilters(payload));
      };

      var _ref5 = shared.sessionPreferences.location || {},
          latitude = _ref5.latitude,
          longitude = _ref5.longitude;

      var _ref6 = prevShared.sessionPreferences.location || {},
          prevLatitude = _ref6.latitude,
          prevLongitude = _ref6.longitude;

      if (latitude !== prevLatitude && longitude !== prevLongitude) {
        this.parseCoordinates(latitude, longitude);
      }

      if (!shared.sessionPreferences.currency && (shared.filters.selectedFilters && shared.filters.selectedFilters.maxDefaultPrice || shared.filters.selectedFilters && shared.filters.selectedFilters.minDefaultPrice)) {
        updateFilters([{ key: 'minDefaultPrice', value: [] }, { key: 'maxDefaultPrice', value: [] }]);
      }
    }
  }]);
  return FilterPills;
}(_react.Component);

exports.default = FilterPills;