'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useFeatureFlag = undefined;

var _reactRedux = require('react-redux');

/**
 * Gets a feature flag from the global state
 */
var useFeatureFlag = exports.useFeatureFlag = function useFeatureFlag(flagName) {
  var featureFlags = (0, _reactRedux.useSelector)(function (state) {
    return state.config.config.global.featureFlags;
  });

  return featureFlags[flagName];
};