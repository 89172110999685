'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    flex-direction: ', ';\n    margin-bottom: 100px;\n  '], ['\n    flex-direction: ', ';\n    margin-bottom: 100px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    width: 50%;\n    background-color: #fff;\n    font-size: 14px;\n    font-weight: 300;\n    line-height: 30px;\n    letter-spacing: 0.5px;\n    text-align: left;\n    display: flex;\n    flex-direction: column;\n    justify-content: center\n    align-items: center;\n    max-height: 570px\n  '], ['\n    width: 50%;\n    background-color: #fff;\n    font-size: 14px;\n    font-weight: 300;\n    line-height: 30px;\n    letter-spacing: 0.5px;\n    text-align: left;\n    display: flex;\n    flex-direction: column;\n    justify-content: center\n    align-items: center;\n    max-height: 570px\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    font-size: 20px;\n    font-weight: 400;\n    line-height: 30px;\n    letter-spacing: 2.5px;\n    text-align: left;\n  '], ['\n    font-size: 20px;\n    font-weight: 400;\n    line-height: 30px;\n    letter-spacing: 2.5px;\n    text-align: left;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    font-size: 14px;\n    font-weight: 300;\n    line-height: 28px;\n    letter-spacing: 0.5px;\n    text-align: left;\n\n  '], ['\n    font-size: 14px;\n    font-weight: 300;\n    line-height: 28px;\n    letter-spacing: 0.5px;\n    text-align: left;\n\n  ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n    width: 50%;\n  '], ['\n    width: 50%;\n  ']),
    _templateObject6 = (0, _taggedTemplateLiteral3.default)(['\n  margin-bottom: 0px\n  width: 276px;\n  height: 46px;\n  margin-top: 0px;\n  '], ['\n  margin-bottom: 0px\n  width: 276px;\n  height: 46px;\n  margin-top: 0px;\n  ']),
    _templateObject7 = (0, _taggedTemplateLiteral3.default)(['\n    font-size: 12px;\n    line-height: 22px;\n  '], ['\n    font-size: 12px;\n    line-height: 22px;\n  ']);

exports.default = RRMCFiftyFifty;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactWaypoint = require('react-waypoint');

var _reactWaypoint2 = _interopRequireDefault(_reactWaypoint);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _navigation = require('../../../helpers/navigation');

var _navigation2 = _interopRequireDefault(_navigation);

var _useCheckIsMobileScreenRollsRoyce = require('../../../hooks/useCheckIsMobileScreenRollsRoyce');

var _useCheckIsMobileScreenRollsRoyce2 = _interopRequireDefault(_useCheckIsMobileScreenRollsRoyce);

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FiftyFiftyContainer = _styledComponents2.default.div.withConfig({
  displayName: 'FiftyFifty'
}).withConfig({
  displayName: 'RRMCFiftyFifty__FiftyFiftyContainer',
  componentId: 'sc-1j6hrbo-0'
})(['width:100%;display:flex;flex-direction:column;', ';'], _theme2.default.min.large(_templateObject, function (_ref) {
  var left = _ref.left;
  return left ? 'row' : 'row-reverse';
}));

var FiftyFiftyContentWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'RRMCFiftyFifty__FiftyFiftyContentWrapper',
  componentId: 'sc-1j6hrbo-1'
})(['width:100%;', ';'], _theme2.default.min.large(_templateObject2));
var MotionFiftyFiftyContent = _styledComponents2.default.div.withConfig({
  displayName: 'RRMCFiftyFifty__MotionFiftyFiftyContent',
  componentId: 'sc-1j6hrbo-2'
})(['display:flex;flex-direction:column;padding:24px 16px 32px 16px;max-width:432px;align-items:left;']);
var FiftyFiftyContentHeader = _styledComponents2.default.div.withConfig({
  displayName: 'RRMCFiftyFifty__FiftyFiftyContentHeader',
  componentId: 'sc-1j6hrbo-3'
})(['color:#151515;font-size:18px;font-weight:400;line-height:30px;letter-spacing:2px;text-align:left;text-transform:uppercase;', ';'], _theme2.default.min.large(_templateObject3));
var FiftyFiftyMainContent = _styledComponents2.default.div.withConfig({
  displayName: 'RRMCFiftyFifty__FiftyFiftyMainContent',
  componentId: 'sc-1j6hrbo-4'
})(['color:#151515;text-wrap:balance;font-size:13px;font-weight:300;line-height:25px;letter-spacing:0.5px;text-align:left;padding-top:16px;padding-bottom:40px;', ';'], _theme2.default.min.large(_templateObject4));

var FiftyFiftyImage = _styledComponents2.default.img.withConfig({
  displayName: 'RRMCFiftyFifty__FiftyFiftyImage',
  componentId: 'sc-1j6hrbo-5'
})(['object-fit:cover;width:100%;max-height:570px;', ''], _theme2.default.min.large(_templateObject5));

var ButtonsWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'RRMCFiftyFifty__ButtonsWrapper',
  componentId: 'sc-1j6hrbo-6'
})(['margin-top:27px;', ';'], _theme2.default.min.large(_templateObject6));

var ViewPreOwnedBtn = _styledComponents2.default.button.withConfig({
  displayName: 'RRMCFiftyFifty__ViewPreOwnedBtn',
  componentId: 'sc-1j6hrbo-7'
})(['padding:0px;margin:0px;transition:background-color 500ms cubic-bezier(0.455,0.03,0.515,0.955);position:relative;display:inline-block;background-color:transparent;transition:background-color 0.4s;border:unset;font-size:12px;color:#151515;font-family:\'RivieraNights-Medium\';letter-spacing:2px;text-transform:uppercase;font-weight:500;line-height:24px;letter-spacing:2px;text-align:left;&:after{position:absolute;top:2px;content:\'\';display:inline-block;transition:all 300ms cubic-bezier(0.455,0.03,0.515,0.955);line-height:16px;width:50px;height:16px;background-position:center left 10px,center left 5px;background-size:16px 16px;background-repeat:no-repeat;background-image:', ';}&:hover,&:active{opacity:0.7;}&:hover:after,&:active:after{opacity:1;transform:translateX(5px);}', ';'], function (_ref2) {
  var icon = _ref2.icon;
  return 'url(\'' + (icon || 'https://res.cloudinary.com/motortrak/image/upload/v1637665531/locator/rolls-royce/global/icons/rr-button-arrow.svg') + '\')';
}, _theme2.default.min.large(_templateObject7));

function RRMCFiftyFifty(_ref3) {
  var buttonLabel = _ref3.buttonLabel,
      content = _ref3.content,
      imageUrl = _ref3.imageUrl,
      imageLeft = _ref3.imageLeft,
      isDesktop = _ref3.isDesktop,
      title = _ref3.title,
      path = _ref3.path;

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      inView = _useState2[0],
      setInView = _useState2[1];

  var _useState3 = (0, _react.useState)(50),
      _useState4 = (0, _slicedToArray3.default)(_useState3, 2),
      transformDistance = _useState4[0],
      setTransformDistance = _useState4[1];

  var isMobile = (0, _useCheckIsMobileScreenRollsRoyce2.default)();
  var contentRef = (0, _react.useRef)(null);

  var _useNavigation = (0, _navigation2.default)(),
      navigateTo = _useNavigation.navigateTo;

  var SCROLL_SPEED = 0.15;
  var MAX_TRANSFORM_DISTANCE = 50;
  var MIN_TRANSFORM_DISTANCE = -50;
  var onButtonClick = function onButtonClick(e) {
    e.preventDefault();
    if (path) {
      navigateTo(path);
    }
  };

  var handleScroll = function handleScroll() {
    if (contentRef.current && !isMobile && inView) {
      var rect = contentRef.current.getBoundingClientRect();
      var top = rect.top,
          bottom = rect.bottom;


      var middleOfElement = (bottom - top) / 2;
      var distance = (top - middleOfElement) * SCROLL_SPEED;
      if (distance > MAX_TRANSFORM_DISTANCE) {
        setTransformDistance(MAX_TRANSFORM_DISTANCE);
      } else if (distance < MIN_TRANSFORM_DISTANCE) {
        setTransformDistance(MIN_TRANSFORM_DISTANCE);
      } else {
        setTransformDistance(distance);
      }
    }
  };

  (0, _react.useEffect)(function () {
    if (isDesktop) {
      window.addEventListener('scroll', handleScroll);
    } else {
      setTransformDistance(0);
    }
    return function () {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [inView, isMobile]);

  return _react2.default.createElement(
    FiftyFiftyContainer,
    { left: imageLeft },
    _react2.default.createElement(
      FiftyFiftyContentWrapper,
      {
        ref: contentRef,
        left: imageLeft,
        style: {
          transform: 'translateY(' + (!imageLeft ? transformDistance * 2 : transformDistance) + 'px)'
        }
      },
      _react2.default.createElement(
        MotionFiftyFiftyContent,
        { key: title + '-content', id: title },
        _react2.default.createElement(
          FiftyFiftyContentHeader,
          null,
          title
        ),
        _react2.default.createElement(
          FiftyFiftyMainContent,
          null,
          content
        ),
        _react2.default.createElement(
          ButtonsWrapper,
          null,
          _react2.default.createElement(
            ViewPreOwnedBtn,
            { onClick: onButtonClick },
            buttonLabel
          )
        ),
        _react2.default.createElement(_reactWaypoint2.default, {
          onEnter: function onEnter() {
            setInView(true);
          },
          onLeave: function onLeave() {
            setInView(false);
          }
        })
      )
    ),
    _react2.default.createElement(FiftyFiftyImage, {
      src: imageUrl,
      style: {
        transform: 'translateY(' + (imageLeft ? transformDistance * 2 : transformDistance) + 'px)'
      }
    })
  );
}