'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _framerMotion = require('framer-motion');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'MenuIcon__Wrapper',
  componentId: 'sc-1b4i2zg-0'
})(['display:flex;justify-content:center;align-items:center;width:16px;height:16px;margin-right:16px;']);

var CLOSE_DELAY = 1;
var CENTER_DISTANCE = 7;
var TRANSITION_DURATION = 0.4;
var MenuIcon = function MenuIcon(_ref) {
  var open = _ref.open;

  return _react2.default.createElement(
    Wrapper,
    null,
    _react2.default.createElement(
      _framerMotion.MotionConfig,
      {
        transition: {
          duration: TRANSITION_DURATION,
          ease: 'easeInOut'
        }
      },
      _react2.default.createElement(
        _framerMotion.motion.svg,
        {
          width: '16',
          height: '16',
          viewBox: '0 0 16 16',
          animate: open ? 'open' : 'closed'
        },
        '// this is the top line',
        _react2.default.createElement(
          _framerMotion.motion.g,
          {
            variants: {
              closed: {
                rotate: 0,
                y: 0,
                transition: {
                  delay: CLOSE_DELAY
                }
              },
              open: {
                rotate: [0, -45],
                y: [0, CENTER_DISTANCE, CENTER_DISTANCE]
              }
            }
          },
          _react2.default.createElement(_framerMotion.motion.path, {
            'fill-rule': 'evenodd',
            'clip-rule': 'evenodd',
            d: 'M-0.328125 2.28571H8.67188V0H-0.328125V2.28571Z'
          }),
          _react2.default.createElement(_framerMotion.motion.path, {
            'fill-rule': 'evenodd',
            'clip-rule': 'evenodd',
            d: 'M11.6719 2.28571H15.6719V0H11.6719V2.28571Z'
          })
        ),
        '// this is the middle line',
        _react2.default.createElement(_framerMotion.motion.path, {
          'fill-rule': 'evenodd',
          'clip-rule': 'evenodd',
          d: 'M-0.328125 9.14286H15.6719V6.85714H-0.328125V9.14286Z',
          variants: {
            closed: {
              opacity: 1,
              transition: {
                delay: CLOSE_DELAY
              }
            },
            open: { opacity: [1, 0, 0] }
          }
        }),
        '// this is the bottom line',
        _react2.default.createElement(_framerMotion.motion.path, {
          'fill-rule': 'evenodd',
          'clip-rule': 'evenodd',
          d: 'M-0.328125 16H15.6719V13.7143H-0.328125V16Z',
          variants: {
            closed: {
              rotate: 0,
              y: 0,
              transition: {
                delay: CLOSE_DELAY
              }
            },
            open: {
              rotate: [0, 45],
              y: [0, -CENTER_DISTANCE, -CENTER_DISTANCE]
            }
          }
        })
      )
    )
  );
};

exports.default = MenuIcon;